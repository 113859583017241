// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_9867 #slider {
  position: relative;
  height: 107vh !important;
  max-height: 1080px !important;
  min-height: 750px !important;

  @media only screen and (max-width: 990px) {
    min-height: 1100px !important;
  }

  // for top of page gradient
  &:after {
    @include before(100%, 518px);
    background: url(/i/design/gradient-btm.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.8;
  }

  .backstretch {
    position: fixed !important;
    height: 100vh !important;
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 400px !important;
  height: 400px !important;
  z-index: 1;

  @media only screen and (max-width: 990px) {
    min-height: 550px !important;
    height: 550px !important;
  }

  // for top of page gradient
  &:before {
    @include before(100%, 229px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 70px;
    left: 0;

    @media only screen and (max-width: 990px) {
      top: 0;
    }
  }
}

.backstretch {
  position: fixed !important;
  height: 100vh !important;



  img {
    top: 0px !important;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;

    @media only screen and (max-width: 990px) {}
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 210px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 72px;
  line-height: 1;
  font-weight: 800;
  font-family: 'Grandstander', cursive;
  width: fit-content;

  @media only screen and (max-width: 1120px) {
    font-size: 60px;
  }

  @media only screen and (max-width: 990px) {
    bottom: 490px;
  }

  @media only screen and (max-width: 880px) {
    font-size: 45px;
  }

  @media only screen and (max-width: 650px) {
    top: 570px;
    font-size: 35px;
  }

  @media only screen and (max-width: 600px) {
    top: 530px;
    padding: 0 10px;
    font-size: 20px;
    line-height: 32px;
  }

}

#home-btns {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  gap: 7px;

  @media only screen and (max-width: 1040px) {
    gap: 0;
  }

  @media only screen and (max-width: 990px) {
    flex-direction: column;
    // position: relative;
  }

  li {
    background: none;
    padding: 0;
    margin: 0;
    width: 400px;
    height: 114px;
    position: relative;
    transition: 0.3s;

    @media only screen and (max-width: 990px) {
      width: 100%;
    }

    &:hover {
      filter: brightness(105%);
    }

    &:nth-of-type(1),
    &:nth-of-type(3) {
      background: #0099DB;
      background: linear-gradient(90deg, rgba(#0099DB, 0.9) 0%, rgba(#20388C, 0.9) 100%);

      @media only screen and (max-width: 600px) {
        background: linear-gradient(90deg, rgba(#0099DB, 1) 0%, rgba(#20388C, 1) 100%);
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(4) {
      background: #73C794;
      background: linear-gradient(90deg, rgba(#73C794, 0.9) 0%, rgba(#00A64E, 0.9) 100%);

      @media only screen and (max-width: 600px) {
        background: linear-gradient(90deg, rgba(#73C794, 1) 0%, rgba(#00A64E, 1) 100%);
      }
    }

    a {
      color: #fff;
      line-height: 114px;
      font-weight: 800;
      font-size: 30px;
      display: block;

      @media only screen and (max-width: 1040px) {
        font-size: 25px;
      }

      @media only screen and (max-width: 940px) {
        font-size: 30px;
      }

    }
  }
}